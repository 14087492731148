<template>
  <div id="app">
    <div
      v-if="$root.ready && overflow"
      class="ready-overflow"
      @click="openWebsite()"
    >
      <button
        class="btn btn-outline-primary text-uppercase"
        @click="openWebsite()"
      >
        Buka Undangan
      </button>
    </div>

    <AGWAudio
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
      ref="audio"
    />
    <AGWHero
      overlay
      :images="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="agw-content bg-white"
      :style="{ 'margin-top': `${$root.hero.height}px` }"
    >

      <AGWPawiwahan id="pawiwahan" class="bg-blue-light py-5" />
      <AGWIntro id="intro" />
      <AGWMepandes id="mepandes" class="pad-y-60" :mepandes="mepandes" />

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="py-5" />
      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3947.0187150704846!2d114.12380311478225!3d-8.399819393958833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zOMKwMjMnNTkuNCJTIDExNMKwMDcnMzMuNiJF!5e0!3m2!1sen!2sid!4v1607140186906!5m2!1sen!2sid"
        directionUrl="https://www.google.com/maps/dir//-8.3998194,114.1259918/@-8.3998194,114.1238031,17z/data=!4m2!4m1!3e0?hl=en"
        :location="location"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <section class="footer bg-white pad-y-20" ref="footer">
      <div class="font-weight-lighter">
        &copy; {{ $moment().format("YYYY") }}. Made by
        <a href="https://ceremonyku.com">ceremonyku</a>.
      </div>
    </section>

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/onky-uswatun/SKF_4206_RK9oWPraiVZM.jpg?tr=w-800";
const ImageHero2 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/onky-uswatun/SKF_4165_i_wai4z0NwEM.jpg?tr=w-800";
const ImageHero3 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/onky-uswatun/SKF_4183_wCjDZCm66v_u.jpg?tr=w-800";

import Audio from "@/assets/audio/marry-your-daughter.mp3";

import AGWHero from "@/components/Hero/HeroTwo";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery/GalleryOne";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Attendance from "@/components/Attendance";

import { setInterval } from "timers";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
  },
  data() {
    return {
      title: "Pernikahan",
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      location: "Dsn. Sumbergayam , RT. 01, RW. 02, Dasri, Kec. Tegalsari - Banyuwangi",
      time: {
        start: this.$moment("2020-12-28 10:00"),
        end: "Selesai",
      },
      wedding: {
        man: {
          name: "Moh Onky Hendaru Setiawan",
          surname: "Onky",
          parents: "Nur Efendi & Siti Holifah",
          description: "Putra pertama",
          address: "Jenggawah - Jember",
          photo:
            "https://ik.imagekit.io/aryraditya/ceremonyku/onky-uswatun/SKF_4287_atPY-Wqe-Gbv.jpg?tr=w-400",
        },
        woman: {
          name: "Uswatun Hasanah",
          surname: "Uswatun",
          parents: "Markaban & Miswati",
          address: "Dsn. Sumbergayam , RT. 01, RW. 02, Dasri, Kec. Tegalsari - Banyuwangi",
          description: "Putri kedua",
          photo:
            "https://ik.imagekit.io/aryraditya/ceremonyku/onky-uswatun/SKF_4275_shEMxqvZB0NV.jpg?tr=w-400",
        },
      },
      mepandes: [],
      gallery: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true,
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false,
      },
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    },
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.sw.refreshing) return;
        this.sw.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  },
};
</script>
