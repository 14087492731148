<template>
  <section class="pad-y-60 pad-b-30 text-center">
    <div class="container">
      <div class="heading" data-aos="fade-down">
        <h2 class="title font-secondary fs-40 text-teal-dark">
          Assalamualaikum Warahmatullahi Wabarakatuh
        </h2>
      </div>

      <p
        class="font-weight-lighter mar-bottom-25"
        data-aos="fade-right"
        data-aos-delay="300"
      >
        Dengan segala kerendahan hati dan penuh syukur atas karunia Allah SWT,
        Kami mengundang Bapak/Ibu/Saudara/i untuk menghadiri acara pernikahan
        kami pada :
      </p>

      <div class="row justify-content-center">
        <div
          class="col-md-4 col-6 text-center mar-bottom-25"
          data-aos="fade-right"
          data-aos-delay="500"
        >
          <div>
            <img
              src="@/assets/icons/calendar-2.svg"
              style="width: 100%; max-height: 50px"
            />
            <div class="text-uppercase text-muted font-weight-lighter mt-3">
              HARI / TANGGAL
            </div>
            <div class="font-weight-bold fs-18">
              {{ $moment($parent.time.start).format("dddd, DD MMMM YYYY") }}
            </div>
          </div>
        </div>
        <div
          class="col-md-4 col-6 text-center mar-bottom-25"
          data-aos="fade-down"
          data-aos-delay="500"
        >
          <div>
            <img
              src="@/assets/icons/clock-2.svg"
              style="width: 100%; max-height: 50px"
            />
            <div class="text-uppercase text-muted font-weight-lighter mt-3">
              WAKTU
            </div>
            <div class="font-weight-bold fs-18">
              {{ $moment($parent.time.start).format("HH.mm") }} -
              {{ $parent.time.end }}
            </div>
          </div>
        </div>

        <div
          class="col-md-4 col-12 text-center mar-bottom-25"
          data-aos="fade-left"
          data-aos-delay="500"
        >
          <div>
            <img
              src="@/assets/icons/marker-2.svg"
              style="width: 100%; max-height: 50px"
            />
            <div class="text-uppercase text-muted font-weight-lighter mt-3">
              Lokasi
            </div>
            <div class="font-weight-bold fs-18" v-html="$parent.location"></div>
          </div>
        </div>
      </div>

      <p class="font-weight-lighter" data-aos="fade-left" data-aos-delay="600">
        Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila
        Bapak/Ibu/Saudara/i berkenan hadir dan memberikan doa restu kepada kami. 
        Atas kehadiran serta doa restu Bapak/Ibu/Saudara/i,
        kami sekeluarga mengucapkan terima kasih. Kami yang berbahagia.
      </p>

      <h3
        class="font-secondary text-teal-dark mb-0 mar-top-25"
        data-aos="fade-up"
        data-aos-delay="700"
      >
        {{ $parent.wedding.man.surname }} & {{ $parent.wedding.woman.surname }}
      </h3>
    </div>
  </section>
</template>

<script>
export default {};
</script>